// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import PyaroIcon from "../../assets/img/payro/payro_icon.jpeg";
import PyaroText from "../../assets/img/payro/payro_text.jpg";
import { useTranslation } from "react-i18next";
import payroLogo from "../../assets/img/icons/payro.png";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, message, brandText } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  // let mainText = useColorModeValue("navy.700", "white");
  // let mainText = useColorModeValue("navy.700", "white");
  let mainText = useColorModeValue("brand.300", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarPosition = "fixed";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  const navbarBg = useColorModeValue("white", "#1B254B");
  // let navbarBg = useColorModeValue(
  //   "rgba(20, 247, 254, 0.2)",
  //   "rgba(11,20,55,0.5)"
  // );
  let navbarBorder = useColorModeValue("gray.300");
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      // borderColor={navbarBorder}
      borderBottomColor={navbarBorder}
      backgroundSize="cover"
      borderRadius="0px"
      borderWidth="1px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      w={{
        base: "calc(100vw - 0%)",
        md: "calc(100vw - 8%)",
        lg: "calc(100vw - 6%)",
        xl: "calc(100vw - 350px)",
        "2xl": "calc(100vw - 365px)",
      }}
    >
      <Flex w="100%" mb={gap}
            styles={{zIndex:"100", color:"blue"}}
      >
        {/* <SidebarResponsive routes={routes} /> */}
        {brandText === "sidebar_main" ? (
          <Flex
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            marginLeft={"10px"}>
            <Image mt={3} width={"70%"} src={payroLogo} alt="Payro Logo" />

            {/*<Image*/}
            {/*  src={PyaroText}*/}
            {/*  width={"130px"}*/}
            {/*  height={"40px"}*/}
            {/*  mt={"7px"}*/}
            {/*  me={"10px"}*/}
            {/*></Image>*/}
            {/*<Image src={PyaroIcon} width={"40px"} height={"40px"} />*/}
          </Flex>
        ) : (
          <Text
            color={mainText}
            fontWeight="bold"
            fontSize="28px"
            style={{
              marginLeft: "0px",
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t(brandText)}
          </Text>
        )}
      </Flex>
      {secondary ? <Text color="white">{message}</Text> : null}
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
