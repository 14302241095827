import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useColorMode,
  Image, Link,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {Page} from "../../nav/navigator";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getMobileOperatingSystem, Mobile } from "../../utils/DeviceUtils";
import { useAuth } from "../../auth-context/auth.context";
import { LogEvent } from "../../analytics/analytics";
import payroLogo from "../../assets/img/icons/payro.png";
import welcomeBackground from "../../assets/img/layout/welcome_bg.png"; // Adjust the path as needed

const Welcome = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();
  const { user } = useAuth();

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  function handleGoToSignIn() {
    history.push(Page.SignIn.value);
  }

  useEffect(() => {
    if(colorMode === "light") {
      toggleColorMode()
    }
    if (user) {
      history.push('/rtl-default')
    }
    const referrers = ["flyer1222", "ecp", "hc", "halperin", "dominos", "emun", "dom"];

    const referrer = getParameterByName("referrer");
    const mobileOs = getMobileOperatingSystem();
    if(referrer && referrers.includes(referrer)) {
      LogEvent("welcome_home", { referrer: referrer })
      if(mobileOs === Mobile.Android) {
        let now = new Date().valueOf();
        setTimeout(function () {
          if (new Date().valueOf() - now > 100) return;
          window.location.href = "market://details?id=io.payro.mobile";
        }, 1000);
        window.location.href = "payro://open";
      }
      else if(mobileOs === Mobile.iOS) {
        let now = new Date().valueOf();
        setTimeout(function () {
          if (new Date().valueOf() - now > 100) return;
          window.location.href = "https://apps.apple.com/us/app/payro/id1671643826";
        }, 500);
        window.location.href = "payro://";
      }
    } else {
      if(mobileOs === Mobile.Android) {
        window.location.href = "payro://open";
      }
      else if(mobileOs === Mobile.iOS) {
        window.location.href = "payro://";
      }
        LogEvent("welcome_home")
    }
  }, [])

  const handleWelcomeWizardClick = () => {
    history.push(Page.WelcomeInfo.value);
  };

  const handleLoginClick = () => {
    history.push(Page.SignIn.value);
  };

  const handleDownloadNow = () => {
    const mobileOs = getMobileOperatingSystem();
    if(mobileOs === Mobile.Android) {
      window.location.href = "market://details?id=io.payro.mobile";
    }
    else if(mobileOs === Mobile.iOS) {
      window.location.href = "https://apps.apple.com/us/app/payro/id1671643826";
    }
  };

  const mobileOs = getMobileOperatingSystem();

  return (
      <Box
          style={{
            height: mobileOs === Mobile.Android ? "calc(100vh - 225px)" : "calc(100vh - 250px)",
            backgroundImage: `url(${welcomeBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={5}
      >
        <Image src={payroLogo} alt="Payro Logo" />

        <Stack alignItems="center"  style={{ marginBottom: '50px' }}>
          <Heading
              style={{ marginTop: '50px' }}
              fontFamily='Noto Sans Hebrew, sans-serif'
              mt="5"
              textColor={"#2E3136"}
              fontSize="34px"
              fontWeight="bold"
              textAlign="center"
          >
            {t('welcome_to_payro_title')}
          </Heading>

          <Text
              mt="10 !important"
              fontSize="18px"
              fontFamily='Noto Sans Hebrew, sans-serif'
              lineHeight="1.4"
              fontWeight={'regular'}
              textColor={"#2E3136"}
              textAlign="center"
          >
            {t('welcome_to_payro_subtitle_1')}
            <br />
            <br />
            {t('welcome_to_payro_subtitle_2')}
          </Text>
        </Stack>

        <Button fontWeight={'bold'} fontSize={'24px'} style={{ marginTop: '80px', width: '100%' }} variant="newBrand" onClick={handleDownloadNow}>
          הורידו עכשיו
        </Button>
        <Link
            mt={2}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize="16px"
            fontFamily='Noto Sans Hebrew, sans-serif'
            textColor={"#2E3136"}
            onClick={(e) => {
              e.preventDefault(); // Prevent default link behavior
              handleGoToSignIn();
            }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
        >
          לחצו כאן לטלפון כשר
        </Link>

      </Box>
  );
};

export default Welcome;
