/* eslint-disable */
import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text, useColorMode,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
import { useTranslation } from "react-i18next";
// Assets
import { useAuth } from "../../../auth-context/auth.context";
import AuthApi from "../../../api/auth";
import PyaroIcon from "../../../assets/img/payro/payro_icon.jpeg";
import PyaroText from "../../../assets/img/payro/payro_text.jpg";
import i18n, { setLanguage } from "i18n";
import { GoChevronDown } from "react-icons/go";
import FadeTransition from "components/pageTransition/FadeTransiton";
import {LogEvent, LogEventParams, setSessionToken} from "../../../analytics/analytics";
import {openWhatsAppSupport} from "../../../utils/Support";

const changeLanguage = (ln) => {
  LogEventParams("otp_set_language", { lang: ln.target.value });
  setLanguage(ln.target.value);
};

function Otp() {
  const [otpCode, setOtpCode] = useState(""); // <-- Default values HERE
  const [error, setError] = useState(undefined);
  const history = useHistory();
  const { setUser, user } = useAuth();

  useEffect(() => {
    LogEvent("otp_view");
    if (user) {
      history.push('/rtl-default')
    }
  }, []);

  const goBack = () => {
    return history.goBack();
  };

  const goToMain = () => {
    history.replace("/rtl-default");
  };

  const goToTermsConditions = () => {
    return history.push("/auth/tc");
  };

  const getUserInfo = async (response) => {
    let accessToken = response.accessToken;

    try {
      let response = await AuthApi.GetUserInfo(accessToken);
      if (response.data && response.data.success === false) {
        return setError(response.data.msg);
      }
      const userInfo = {
        token: accessToken,
        info: {
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          type: response.data.type,
        },
      };
      setSessionToken(accessToken);
      setUser(userInfo);
      localStorage.removeItem("tempSessionToken");
      return goToTermsConditions();
      /*
      if(response.data.approveTc === true) {
        return goToMain();
      } else {
        return goToTermsConditions();
      }
      */
    } catch (err) {
      console.log(err);
      if (err && err.response && err.response.status === 401) {
        return setError("קוד לא תקין");
      } else if (err.message) {
        return setError(err.message);
      }
      return setError("שגיאה");
    }
  };

  const handleContinue = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (otpCode === "") {
      return setError(t("login_2_textfield_missing_error_text"));
    }

    if (otpCode.length !== 6) {
      return setError(t("login_2_textfield_valid_error_text"));
    }
    try {
      let response = await AuthApi.Login(otpCode);
      if (response.data && response.data.success === false) {
        return setError(response.data.msg);
      }
      return getUserInfo(response.data);
    } catch (err) {
      console.log(err);
      if (err && err.response && err.response.status === 401) {
        return setError("קוד לא תקין");
      } else if (err.message) {
        return setError(err.message);
      }
      return setError("שגיאה");
    }
  };

  const onInput = (e) => {
    if (e.target.value.length > e.target.maxLength)
      e.target.value = e.target.value.slice(0, e.target.maxLength);
  };

  const { t } = useTranslation();

  return (
    <FadeTransition>
      <DefaultAuth>
        <Stack minH="calc(100vh - 175px)">
          <Stack spacing="0" mt={"2"}>
            <VStack mb="0px">
              <Heading textAlign="center" fontSize="34px" fontWeight="bold"
                       fontFamily="Noto Sans Hebrew, sans-serif"
                       textColor={'#2E3136'}
              >
                {t('welcome_to_payro_title')}
              </Heading>
              <Text
                  fontFamily="Noto Sans Hebrew, sans-serif"
                  textColor={'#2E3136'}
                  textAlign="center"
                maxW="32ch"
                mt="22px !important"
                fontSize="18px"
                lineHeight="1.4"
              >
                {t('login_2_message')}
              </Text>
            <h4
              style={{
                fontSize: ".9em",
                color: "red",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all",
              }}
            >
              {error}
            </h4>
            </VStack>
          </Stack>
            <Stack spacing="10" style={{marginTop:"50px"}}>
              <Input
                borderRadius="4px"
                px="3"
                textAlign="center"
                justifyContent="center"
                isRequired={true}
                type="number"
                maxLength="6"
                placeholder={t("login_2_textfield_hint")}
                onInput={onInput}
                variant="auth"
                fontSize="18px"
                _focus={{ borderColor: "newBrand.500" }}
                fontWeight="500"
                height="60px"
                fontFamily="Noto Sans Hebrew, sans-serif"
                textColor={'#2E3136'}
                borderColor={"secondaryGray.600"}

                onChange={(event) => {
                  setOtpCode(event.target.value);
                  setError(undefined);
                }}
              />
              <Text fontSize="18px" textAlign="center">
                {t('login_2_code_1')}
                <Box as="span" onClick={goBack} fontWeight="bold" textDecor="underline" mr="2">
                  {t('login_2_code_2')}
                </Box>
              </Text>
              <Button variant="newBrand" onClick={handleContinue}>
                {t("login_1_button_text")}
              </Button>
            </Stack>
          {/*<Text pt="1" fontSize="18px" textAlign="center" >*/}
          {/*  {t('employer_not_offering_payro')}*/}
          {/*  <Box as="span" fontWeight="bold" textDecor="underline" mr="2">*/}
          {/*    {t('connect_us_to_your_employer')}*/}
          {/*  </Box>*/}
          {/*</Text>*/}
          {/*<Stack spacing="0">*/}
          {/*  <Center pt="4">*/}
          {/*    <Menu display="flex" alignItems="center" justifyContent="center">*/}
          {/*      <MenuButton as={Button} rightIcon={<GoChevronDown />}>*/}
          {/*        {t("language_native")}*/}
          {/*      </MenuButton>*/}
          {/*      <MenuList>*/}
          {/*        <MenuItem value="heb" onClick={changeLanguage}>*/}
          {/*          עברית*/}
          {/*        </MenuItem>*/}
          {/*        <MenuItem value="en" onClick={changeLanguage}>*/}
          {/*          English*/}
          {/*        </MenuItem>*/}
          {/*        <MenuItem value="ar" onClick={changeLanguage}>*/}
          {/*          عربى*/}
          {/*        </MenuItem>*/}
          {/*      </MenuList>*/}
          {/*    </Menu>*/}
          {/*  </Center>*/}
          {/*</Stack>*/}
        </Stack>
      </DefaultAuth>
    </FadeTransition>
  );
}

export default Otp;
