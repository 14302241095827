import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Heading,
    Image,
    Input,
    Menu,
    MenuButton, MenuItem, MenuList,
    Stack,
    Text,
    useColorMode,
    VStack, Textarea, Checkbox
} from "@chakra-ui/react";
import {useHistory} from "react-router-dom";
import {getMobileOperatingSystem, Mobile} from "../../../utils/DeviceUtils";
import {useTranslation} from "react-i18next";
import ReactSession from "../../../utils/ReactSession";
import {LogEvent} from "../../../analytics/analytics";

function TermsConditions(props) {
    const [approved, setApproved] = useState(false)
    const [error, setError] = useState(undefined)
    const history = useHistory()
    const { t } = useTranslation();

    useEffect(() => {
        LogEvent('tc_view')
    }, [])

    const handleContinue = async (event) => {
        ReactSession.set("tc_view", "1")
        if(approved === false) {
            setError(t('terms_conditions_error_message'))
        } else {
            history.push('/rtl-default')
        }
    }

    const onChange = (event) => {
        setApproved(event.target.checked)
    }

    const mobileOs = getMobileOperatingSystem()

    return (
            <Stack
                minH={ mobileOs === Mobile.Android ? "calc(100vh - 225px)" : "calc(100vh - 250px)"}
                minW="calc(100vw- 50px)"
                style={{backgroundColor:'transparent'}}
            spacing={'3'}>
                <Heading textAlign="center" fontSize="28px" fontWeight="bold"
                         fontFamily="Noto Sans Hebrew, sans-serif"
                         textColor={'#2E3136'}
                >
                    {t('welcome_to_payro_title')}
                </Heading>
                {/*<iframe*/}
                {/*    width="100%"*/}
                {/*    height="90%"*/}
                {/*    style={{height: 'calc(100vh - 250px)', backgroundColor:"red"}}*/}
                {/*    id="id1"*/}
                {/*    className="myClassname"*/}
                {/*    display="initial"*/}
                {/*    position="relative"*/}
                {/*    src="../../tc.html"/>*/}
                {/*<div  style={{height: 'calc(100vh - 300px)',overflow:"scroll", outline:"0", listStyleType: "upper-roman"}} >*/}
                {/*    <p>*/}
                {/*        גלישתך באתר פיירו, שימוש באפליקציית פיירו, ושימוש בשירותים הקיימים בהם,*/}
                {/*        מהווה הסכמה לתנאים הבאים:*/}
                {/*    </p>*/}
                {/*    <br/>*/}
                {/*    <ol>*/}
                {/*        <li>*/}
                {/*            <strong><u>תנאים כלליים</u></strong>*/}
                {/*        </li>*/}
                {/*    </ol>*/}
                {/*        <ol>*/}
                {/*            <br/>*/}

                {/*            <li>*/}
                {/*                            מסמך זה הוא הסכם בינך (להלן: "<strong>אתה</strong>" או ה"<strong>משתמש</strong>") לבין חברת פיירו טכנולוגיות פיננסיות בע"מ*/}
                {/*                            (להלן: "<strong>החברה</strong>") בנוגע לשימוש, ביצוע פעולות, לצפייה*/}
                {/*                            ו/או הורדה של תכנים ולקבלת שירותים שונים המוצעים לך באמצעות האתר ו/או*/}
                {/*                            האפליקציה של החברה ("<strong>השירותים</strong>"). כאשר אתה משתמש*/}
                {/*                            בשירותים, או מאפשר לאדם אחר לעשות זאת, אתה מקבל על עצמך את כל תנאי הסכם*/}
                {/*                            זה וכן את תנאי השירות הספציפי. החברה זכאית לשנות תנאי שימוש אלה מעת*/}
                {/*                            לעת.*/}
                {/*            </li>*/}
                {/*        </ol>*/}
                {/*</div>*/}
                {/*<Textarea readonly="readonly" unselectable="on" height="calc(100vh - 225px)" style={{webkitUserSelect: "none"}}>*/}
                <div  style={{
                    height: mobileOs === Mobile.Android ?  'calc(100vh - 350px)' : 'calc(100vh - 400px)',
                    overflow:"scroll",
                    outline:"0",
                    padding: "5px",
                    color: "#2E3136",
                    border: "1px solid #FFFFFF50"}} >
                    <p dir="RTL">
                        גלישתך באתר פיירו, שימוש באפליקציית פיירו, ושימוש בשירותים הקיימים בהם,
                        מהווה הסכמה לתנאים הבאים:
                    </p>
                    <br/>
                    <ol start="1" type="1" style={{ listStyleType: "upper-roman" }}>
                        <li dir="RTL">
                            <strong><u>תנאים כלליים</u></strong>
                        </li>
                    </ol>
                    <ol start="1" type="1" style={{ listStyleType: "upper-roman" }}>
                        <li dir="RTL">
                            מסמך זה הוא הסכם בינך (להלן: "<strong>אתה</strong>" או ה"<strong>משתמש</strong>") לבין חברת פיירו טכנולוגיות פיננסיות בע"מ
                            (להלן: "<strong>החברה</strong>") בנוגע לשימוש, ביצוע פעולות, לצפייה
                            ו/או הורדה של תכנים ולקבלת שירותים שונים המוצעים לך באמצעות האתר ו/או
                            האפליקציה של החברה ("<strong>השירותים</strong>"). כאשר אתה משתמש
                            בשירותים, או מאפשר לאדם אחר לעשות זאת, אתה מקבל על עצמך את כל תנאי הסכם
                            זה וכן את תנאי השירות הספציפי. החברה זכאית לשנות תנאי שימוש אלה מעת
                            לעת.
                        </li>
                    </ol>
                    <br/>
                    <p dir="RTL">
                        <strong>2. </strong>
                        <strong><u>רישום לשירותים </u></strong>
                    </p>
                    <ol start="1" type="1" style={{ listStyleType: "upper-roman" }}>
                        <li dir="RTL">
                            בעת הרישום לשירותים יהיה עליך להזין טופס רישום הכולל את הפרטים הבאים:
                            שם פרטי, שם משפחה, תאריך לידה, מספר תעודת זהות, מספר טלפון, דואר
                            אלקטרוני, יישוב מגורים, סוג שירות מבוקש, גיל, פרטי המעסיק שלך, מספר
                            עובד, פרטי חשבון בנק ופרטים נוספים שייתכן ותידרש להזין ברישום לשירותים.
                            עבור שירותים נוספים וייחודיים ייתכן ותידרש להזין פרטים נוספים כפי שתמצא
                            החברה לנכון מעת לעת. החברה שומרת לעצמה את הזכות להוסיף ו/או לשנות את
                            הפרטים הנכללים בטופס הרישום לשירותים. מסירת פרטים כאמור הינה לשיקול
                            דעתך ואינך מחויב למסור אותם מכוח חוק. עם זאת, שימוש בשירותים מחייב
                            רישום ומסירת פרטים. אנא תשומת ליבך לעובדה כי ייתכן והפרטים המפורטים
                            לעיל יימסרו לנו על ידי המעסיק לאחר הרשמתך הראשונית לשירות.
                        </li>
                    </ol>
                    <ol start="2" type="2">
                        <p dir="RTL">
                            2. השימוש בשירותים מיועד ליחידים בגירים בלבד (מגיל 18 ומעלה)!
                        </p>
                    </ol>
                    <p dir="RTL">
                        3. הינך נדרש לוודא את נכונות ואמיתות הנתונים טרם הזנתם בטופס הרישום
                        לשירותים. מסירת מידע שגוי, לא מדויק, או מפוברק, עלולה לפגוע ביכולת החברה
                        לספק לך את השירותים ותהיה באחריותך הבלעדית (לרבות בקשר עם העברת כספים
                        לנמענים אחרים). החברה לא תהיה אחראית לכל נזק שייגרם לך בקשר עם מסירת מידע
                        שגוי, לא מדויק או מפוברק ולא תעמוד לך כל טענה בהקשר זה.
                    </p>
                    <p dir="RTL">
                        <strong>3. </strong>
                        <strong><u>שימוש בשירותים</u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            החברה מעניקה לך זכות אישית, מוגבלת, שאינה בלעדית, שאינה ניתנת להעברה
                            ושאינה כוללת זכות להעניק רישיון/ות משנה, לגשת ולהשתמש בשירותים בכפוף
                            לתנאי שימוש אלו וזאת אך ורק לצורך קבלת השירותים (להלן: "        <strong>הרישיון</strong>"). מתן רישיון כאמור מותנה בעמידה בתנאי שימוש
                            אלו ובתשלום דמי העברה ו/או כל תשלום אחר אשר יידרש בהתאם להוראות תנאי
                            שימוש אלו במלואו ובמועדו.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. ייתכן והשירותים יכללו, בין היתר, הצגת מידע שכר הנוגע להעסקתך אצל המעסיק.
                        החברה אינה מתחייבת כי מידע זה יהיה עדכני ומדויק וייתכן כי מידע זה או חלקו
                        יתבסס על הערכה בלבד. כמו כן, ייתכן ומידע כאמור ישתנה בהתאם לשעות העבודה
                        בפועל, מדרגות מיסוי להם הינך כפוף, נקודות זיכוי להן אתה זכאי וכיוצא באלה
                        פרטים להם לחברה אין גישה ואינם בידיעתה.
                    </p>
                    <p dir="RTL">
                        3. שימוש בשירותים כרוך בתשלום דמי שירות על ידי המשתמש כפי שמפורט בשירותים
                        עצמם. אלא אם נאמר אחרת מפורשות בשירותים, כל שימוש בשירותים באמצעותו תבוצע
                        העברת כספים לחשבונך יהיה כפוף לתשלום דמי העברה (להלן: "    <strong>דמי העברה</strong>"). סכום דמי ההעברה יפורט בשירותים עצמם. תשלומים
                        בגין שימוש בשירותים יבוצעו על ידך באמצעים אשר יוגדרו בשירותים ו/או ישירות
                        על ידי ניכוי מהשכר לו אתה זכאי מהמעסיק. ככלל התשלומים המפורטים בשירותים
                        מצוינים בש"ח וכוללים מע"מ.
                    </p>
                    <p dir="RTL">
                        4. החברה תהיה רשאית לשנות את גובה התשלום בגין מי משירותיה, לרבות דמי
                        ההעברה, וזאת בכפוף לפרסום הודעה על שינוי כאמור במסגרת השירותים ובכפוף לכך
                        ששינויים כאמור יחולו החל מהתאריך הקובע עליו תודיע החברה בפרסום הרלוונטי.
                    </p>
                    <p dir="RTL">
                        5. אנו מעודדים אותך לעשות שימוש אחראי ומושכל בשירותים לרבות בכל הנוגע
                        לריכוז משיכות מקדמות השכר שלך ובכך לצמצם את דמי ההעברה שתשלם לחברה.
                    </p>
                    <p dir="RTL">
                        6. לחברה שמורה הזכות שלא לספק את השירותים במקרה ספציפי על פי שיקול דעתה
                        ו/או לבטל את אפשרותך להשתמש במי מהשירותים באם יתגלה כי מסרת פרטים כוזבים,
                        חלקיים או לא מדויקים. בנוסף, החברה תהיה רשאית לסרב ו/או לבטל ו/או שלא לתת
                        שירות מהשירותים בנסיבות ספציפיות על פי שיקול דעתה וזאת לרבות במקרים הבאים:
                        קיומה של יתרת חוב של המשתמש לחברה ו/או למעסיק, סיום העסקתו של המשתמש אצל
                        המעסיק, חישוב שגוי של השכר החלקי לו המשתמש זכאי או כמות השעות אותה עבד
                        וכיוצא באלה.
                    </p>
                    <p dir="RTL">
                        7. החברה תהיה רשאית, על פי שיקול דעתה הבלעדי, להשעות או לחסום, באופן זמני
                        או קבוע, את הגישה שלך לשירותים במקרים של עבירה על הוראות דין או על תנאי
                        השימוש אלו, או אם קיימת הפרעה מכל סוג שהוא לפעילות התקינה של השירותים או של
                        מערכת המחשבים של החברה או פגיעה בזכויות קניין רוחני של החברה או של צד אחר,
                        ולא תהיה לך כל טענה או דרישה כנגד החברה במקרה כאמור.
                    </p>
                    <p dir="RTL">
                        8. מובהר בזאת כי אין במתן השירותים, או כל חלק בהם, כל אשראי ו/או הלוואה
                        שהם. במסגרת השירותים החברה מאפשרת לך לקבל חלק מהשכר לו הינך כבר זכאי בגין
                        עבודתך אצל מעסיק המנוי על שירותי החברה (להלן: "<strong>המעסיק</strong>").
                        החברה אינה צד למישור היחסים בינך ובין המעסיק שלך ובקבלת שירותים מהחברה אין
                        ולא יהיה בכדי להוות כל עילה או דרישה לקיומם של יחסי עובד מעביד או יחסי
                        נאמנות כלשהם בינך ובין החברה.
                    </p>
                    <p dir="RTL">
                        9. המעסיק שלך, ולא החברה, יהיה אחראי לשקף בדו"ח השכר החודשי שלך כל מקדמת
                        שכר ששולמה לך על ידי החברה.
                    </p>
                    <p dir="RTL">
                        10. שים לב, כל תשלום שיועבר לך על ידי החברה ינוכה מהשכר החודשי שלך על ידי
                        המעסיק בצירוף כל עמלה ו/או דמי שימוש שהחברה זכאית לגבות בגין שימושך
                        בשירותים ישירות מהמעסיק שלך. בבקשה לקבלת שירותי הקדמת שכר מהחברה הינך נותן
                        לחברה הרשאה בלתי חוזרת לפנות למעסיק בבקשה לנכות משכרך כל מקדמת שכר שתשולם
                        לך לפני סוף החודש על ידי החברה ו/או עמלה/דמי שימוש. לא תהיה לך כל טענה או
                        דרישה בהקשר זה.
                    </p>
                    <p dir="RTL">
                        11. קבלת השירותים תהיה מותנית ברישום המעסיק לשירותי החברה וכן בקבלת דיווחים
                        שוטפים מאת החברה לעניין זכאותך לקבלת השירותים וגובה הקדמת השכר לה אתה זכאי.
                        כל טענה שתעמוד לך בנוגע לגובה תשלום מקדמת שכר, אי דיוק דיווחים, תשלום
                        זכויות נוספות וכיוצא באלו תתברר למול המעסיק ולא למול החברה. לחברה אין ולא
                        תהיה כל אחריות שהיא בנוגע לתחומים אלו, או כל נושא אחר אשר בינך ובין המעסיק,
                        ואלו יתבררו בלעדית ביניכם ללא מעורבות החברה. במידה וישנו סכסוך כלשהו בינך
                        ובין המעסיק, לרבות בנוגע לקבלת השירותים, הנך נדרש להודיע על כך לחברה באופן
                        מיידי ולא יאוחר מ-3 ימי עסקים.
                    </p>
                    <p dir="RTL">
                        12. הנך מתחייב שבכל מקרה של תשלום עודף, חריג, שגוי ו/או שלא בהתאם לדיווחי
                        המעסיק, כפי שייקבע על ידי החברה ו/או המעסיק (להלן: "    <strong>תשלום יתר</strong>"), מכל סיבה שהיא, תחזיר כל תשלום כאמור לחברה ללא
                        דיחוי ומיד עם דרישתה לעשות כן. לצד זאת, הינך מאשר לחברה להורות למעסיק לקזז
                        כל תשלום יתר מהשכר לו אתה זכאי מהמעסיק. הנך מתחייב לעדכן את החברה מיד עם
                        סיום העסקתך אצל המעסיק ובכל מקרה לא לעשות כל שימוש בשירותים לאחר מועד קבלת
                        הודעה בדבר סיום העסקתך, מכל סיבה שהיא, אצל המעסיק. עיכוב בהחזר תשלום יתר
                        כלשהו יגרור חיוב בריבית פיגורים ובהצמדה.
                    </p>
                    <p dir="RTL">
                        13. אתה רשאי ויכול לסגור את חשבון המשתמש שלך בחברה בכל שלב ומועד. אתה יכול
                        לעשות כן בפורטל המשתמש שלך או במשלוח הודעת דואר אלקטרוני לכתובת
                        support@payro.io. החשבון ייסגר לאחר עיבוד בקשתך לסגירתו על ידי החברה. סגירת
                        החשבון לא תמעט ממחויבויותיך מכוח הסכם זה, אשר ימשיכו לחול בנוגע לכל פעולה
                        שבוצעה טרם סגירת החשבון ו/או מידע שהועבר לידינו בנוגע לאירועים ו/או מצב
                        נתון שחלו טרם סגירת החשבון.
                    </p>
                    <p dir="RTL">
                        <strong>4. </strong>
                        <strong><u>שימוש לא חוקי ואסור</u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            חל איסור להשתמש בשירותים לכל מטרה לא חוקית או לכל מטרה האסורה בתנאי
                            שימוש אלה. אינך רשאי להשתמש בשירותים באופן העלול להזיק לשירותים, לתכנים
                            בשירותים, לרשתות תקשורת או לציוד תקשורת כלשהו (כולל טלפונים ניידים),
                            לשתקם, לגרום להם עומס יתר או לפגוע בהם, או להפריע לשימוש של גורם אחר
                            כלשהו ולהנאה שלו מהשירותים.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. אינך רשאי לנסות לקבל גישה ללא הרשאה לשירותים, לתכנים, לחשבונות של אחרים
                        או למערכות מחשבים או רשתות המחוברות לשירותים באמצעות "פריצה" (Hacking),
                        "כריית סיסמאות" (Password Mining) או בכל אמצעי אחר. אינך רשאי להשיג או
                        לנסות להשיג שירותים או מידע כלשהם באמצעים שלא הועמדו לרשותך באופן מכוון
                        על-ידי החברה.
                    </p>
                    <p dir="RTL">
                        3. השימוש בחלק מהשירותים והצפייה בחלק מהתכנים מוגבלת למחשבים המצויים
                        בישראל. לפיכך, אין לבצע כל פעולה שמטרתה לעקוף בדרך כלשהי מגבלה זו, וביצוע
                        פעולות כאמור יהווה הפרה של זכויות של צדדים שלישיים.
                    </p>
                    <p dir="RTL">
                        4. אתה מסכים להשתמש בשירותים רק לצורך קבלת השירותים. כדוגמה, ומבלי למעט,
                        אתה מסכים לכך שבעת שימוש בשירותים לא תבצע את הפעולות הבאות:
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            שימוש בשירותים למשלוח הודעות או תכנים לצדדים שלישיים שלא ביקשו לקבל
                            הודעות מסוג זה (לרבות לכל מטרה מסחרית או אחרת).
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. השמצה, גידופים, הטרדה, מעקב, איום או כל הפרה אחרת של הזכויות החוקיות
                        (כולל זכויות לגבי פרטיות ופרסום) של אנשים אחרים.
                    </p>
                    <p dir="RTL">
                        3. פרסום, הצבה באינטרנט, טעינה, הפצה או שליחה של כל חומר או מידע לא-ראויים,
                        גסים, משמיצים, מגונים, מסיתים לשנאה, אפליה, גזענות או אלימות, מוציאי לשון
                        הרע, מעודדים עבירות פליליות או אזרחיות, פוגעים בפרטיות, מזיקים, פוגעים
                        ברגשות הציבור ותכנים אשר מהווים הפרה כלשהי של החוק או של התחייבות חוזית.
                    </p>
                    <p dir="RTL">
                        4. טעינה, או פרסום בצורה אחרת, של קבצים המכילים תמונות, צילומים, תוכנות או
                        חומר אחר המוגן באמצעות חוקי קניין רוחני, וחוקים לגבי פרטיות ופרסום, אלא אם
                        הזכויות לגבי חומר זה נמצאות בבעלותך או בשליטתך, או שקיבלת את ההסכמות
                        הדרושות כדי לעשות זאת.
                    </p>
                    <p dir="RTL">
                        5. טעינת קבצים המכילים תוכנות מחשב פוגעניות, לרבות וירוסים, סוסים טרויאניים
                        וכיו"ב, תוכנות ותכנים העלולים לפגוע בתפקוד המחשב או הטלפון הנייד של אדם אחר
                        או ברכושו של אדם אחר.
                    </p>
                    <p dir="RTL">
                        6. פרסום או הצעה למכור או לקנות סחורות או שירותים למטרה עסקית כלשהי, תכנים
                        בעלי אופי מסחרי או תכנים המכילים מידע פרסומי כלשהו.
                    </p>
                    <p dir="RTL">
                        7. טעינת קובץ שהוצב על-ידי משתמש אחר בשירותים ואשר ידוע לך, או שאתה אמור
                        לדעת באופן סביר, כי לא ניתן להפיצו באופן חוקי בצורה זו.
                    </p>
                    <p dir="RTL">
                        8. זיוף או מחיקה של ייחוס של מחבר כלשהו, הודעות משפטיות, הודעות אחרות,
                        ציוני קניין או תוויות לגבי מקור התכנים והשירותים או חומר אחר הכלול בקובץ
                        שנטען.
                    </p>
                    <p dir="RTL">
                        9. הגבלה או מניעה של משתמש אחר משימוש בשירותים ומהנאה מהם.
                    </p>
                    <p dir="RTL">
                        10. יצירת זהות כוזבת כדי להטעות אחרים.
                    </p>
                    <p dir="RTL">
                        11. איסוף, שימוש, הורדה או העתקה אחרת של רשימה של משתמשים ומנויים, או של
                        מידע אחר לגבי משתמשים או שימוש או מתן מידע כזה (תמורת תשלום או ללא תשלום)
                        לאדם או ישות כלשהם.
                    </p>
                    <p dir="RTL">
                        12. אין לבצע קישור עמוק לתכנים שבשירותים או לקשר לתכנים שבשירותים, במנותק
                        מהעמודים שבהם הם מופיעים בשירותים (לדוגמה: אסור לקשר במישרין לתמונה או
                        לקובץ גרפי בשירותים, אלא לעמוד המלא שבו הם מופיעים). כמו כן, אין להציג את
                        השירותים, או תכנים הכלולים בהם, במסגרת שימוש בטכנולוגיה מסוג "מסגרת"
                        (Framing) או כל טכנולוגיה אחרת באמצעותה מוצגים תכנים מהשירותים באופן משולב.
                    </p>
                    <p dir="RTL">
                        על כתובתו המדויקת של דף האינטרנט בשירותים להופיע במקום הרגיל המיועד לכך
                        בממשק המשתמש, לדוגמה: בשורת הכתובת (Status bar) בדפדפן של המשתמש. אין
                        לשנות, לסלף או להסתיר כתובת זו ואין להחליפה בכל כתובת אחרת.
                    </p>
                    <p dir="RTL">
                        <strong>5. </strong>
                        <strong><u>אחריות</u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            כלל התכנים בשירותים ניתנים כפי שהם בלבד (As-Is) ואתה מסכים כי לא תהיה
                            לך כל טענה או דרישה בהקשר זה. במסגרת זו אתה מקבל על עצמך את מלוא
                            האחריות בגין שימוש בתכני השירותים ופוטר את החברה מכל אחריות שהיא (ישירה
                            או עקיפה) בגין שימושך בשירותים והסתמכות על התכנים בשירותים. החברה רשאית
                            לשנות את תכני השירותים מעת לעת על פי שיקול דעתה הבלעדי.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. החברה וספקיה אינם מציגים מצג כלשהו לגבי התאמה, אמינות, זמינות ודייקנות
                        תכני השירותים ו/או קישורים ו/או מודעות ו/או פרסומות המוצגים בשירותים, לכל
                        מטרה שהיא, וכן לגבי היעדר וירוסים או רכיבים מזיקים אחרים בהם. בנוסף, החברה
                        וספקיה אינם מציגים מצג כלשהו לגבי יכולות טכניות ו/או מקצועיות בשירותים.
                    </p>
                    <p dir="RTL">
                        3. לפיכך, החברה (וספקיה) לא יהיו אחראים לכל אובדן, נזק או הפסד, ישיר או
                        עקיף, אשר נגרם כתוצאה מהסתמכות על השירותים. בפרט, אין לסמוך על עצות
                        המתקבלות באמצעות השירותים לקבלת החלטות אישיות, פיננסיות, משפטיות או אחרות
                        ועליך לפנות אל איש מקצוע מתאים לקבלת ייעוץ המותאם למצבך הספציפי ודרישותיך.
                    </p>
                    <p dir="RTL">
                        <strong>6. </strong>
                        <strong><u>קישורים ותכני צדדים שלישיים בשירותים </u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            ייתכן ובשירותים יוצגו        <a href="http://www.netlaw.co.il/SubjectSr_SubSubjID_8.htm">קישורים</a>
                            לדפי אינטרנט, לאתרים, לתכנים, למידע או לכל תוכן אחר שמקורו בצד שלישי ("        <strong>מידע מקושר</strong>"). כמו כן, ייתכן ובשירותים יוצגו תכנים,
                            מידע או כל תוכן אחר אשר מקורם בצד שלישי ("        <strong>תכני צד שלישי</strong>").
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. אתה מודע לכך כי תכני השירותים, המידע המקושר ותכני צד שלישי יכולים להכיל
                        הערות, המלצות, סיפורים, אנקדוטות,
                        <a
                            href="http://www.netlaw.co.il/hotsubject_hotid_30_desc_%D7%97%D7%91%D7%A8%D7%AA%20%D7%94%D7%9E%D7%99%D7%93%D7%A2%20%D7%98%D7%9B%D7%A0%D7%95%D7%9C%D7%95%D7%92%D7%99%D7%99%D7%AA%20%D7%9E%D7%99%D7%93%D7%A2.htm"
                        >
                            מידע
                        </a>
                        אפור וצבעוני הקשור לאירועים, דמויות, מקומות, פעילויות, מידע צרכני, מידע על
                        מוצרים ועוד.
                    </p>
                    <p dir="RTL">
                        3. החברה לא תישא בכל    <a href="http://www.netlaw.co.il/SubjectSr_SubjID_45.htm">אחריות</a> שהיא
                        לנזק, ישיר או עקיף, מפורש או משתמע למידע המקושר ולתכני צד שלישי ואין
                        בקישור, בהפניה אליהם או בהצגתם בשירותים משום אישור, מתן תוקף, המלצה או כל
                        התייחסות אחרת של השירותים.
                    </p>
                    <p dir="RTL">
                        7. <strong><u>פרטיות ואבטחת מידע</u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            איסוף ושמירת נתונים
                        </li>
                    </ol>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            על מנת לספק לך את כלל השירותים אנחנו מחויבים לאסוף מידע מסוים לגבי
                            המשתמשים שלנו. במסגרת זו נבקשך במסגרת הרישום לשירותים להעביר לידינו
                            פרטי זיהוי וקשר מלאים (לרבות שם מלא, כתובת וכתובת דואר אלקטרוני), מספר
                            עובד, פרטי חשבון בנק וכיוצא באלה. כמו כן, ייתכן ויהיה מידע אשר ייאסף
                            אוטומטית (על ידנו ועל ידי ספקים מטעמנו) במסגרת השימוש בשירותים – איסוף
                            כאמור יכול שיתבצע גם באמצעות Cookies.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. לצורך מתן השירותים החברה גם מקבלת מידע אישי אודות העסקתך אצל המעסיק
                        (מידע זה יכול לכלול גם שם מלא, מספר עובד, מספר תעודת זהות, מידע ליצירת קשר,
                        מידע שכר, מידע נוכחות, מידע לגבי חשבון הבנק שלך, מידע אשראי, וכל מידע אחר
                        אשר נמצא אצל המעסיק ורלוונטי לקבלת השירותים). בהקמת חשבון ורישום לקבלת
                        השירותים אתה מאשר לחברה לקבל מידע כאמור ומתחייב כי לא תעלה כל דרישה או טענה
                        למול החברה בנוגע לקבלת מידע כאמור ושימוש בו לצורך מתן השירותים וכאמור בתנאי
                        השימוש בשירותים.
                    </p>
                    <p dir="RTL">
                        2. שמירה על הנתונים ואחסנה במאגר מידע
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            אנו נשמור על פרטיות הנתונים שלך בהתאם לדין. עם זאת, נהיה רשאים לגלות את
                            פרטיך לשם אספקת השירותים, גביית כספים, עריכת חשבונות, ואכיפת זכויותינו
                            על-פי ההסכם, ונהיה רשאים להעביר פרטיך לאחר על-פי סמכות שבדין, ובהתאם
                            לאמור בתנאי שימוש אלו.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. כדי להגן על הנתונים שלך אנו יכולים לדרוש שתספק לנו מידע המאפשר לנו לזהות
                        אותך, כגון מספר טלפון נייד, מספר תעודת זהות ומידע הנוגע לאמצעי התשלום שמסרת
                        לנו לצורך תשלום חשבונותיך.
                    </p>
                    <p dir="RTL">
                        3. הפרטים שתמסור בעת ההתקשרות איתנו, ובכל פניה אחרת שלך אלינו יישמרו במאגר
                        מידע ממוחשב. אין עליך חובה בדין למסור פרטים על עצמך, אבל אם לא תספק לנו
                        פרטים מסוימים, לא נוכל לספק לך שירותים מסוימים במסגרת הסכם זה.
                    </p>
                    <p dir="RTL">
                        3. מה אנחנו יכולים לעשות עם המידע שלך?
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            לספק לך את השירותים, לרבות שירותי הקדמת שכר.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. לשמור איתך על קשר, לתת לך שירות, לקבל ממך משוב לגבי השירות שניתן לך
                        ואיכות השירותים ולמסור לך פרטים בנוגע לשירותים קיימים וחדשים שלנו ושל ספקים
                        מטעמנו (לרבות במסגרת דיוור ישיר).
                    </p>
                    <p dir="RTL">
                        3. לבצע מחקרי שוק, סקרים, עיבודים וניתוחים סטטיסטיים, לרבות פענוח השימושים
                        אותם מבצעים המשתמשים בשירותים.
                    </p>
                    <p dir="RTL">
                        4. במסגרת אספקת שירותים מסוימים, אנחנו צריכים להעביר לצדדים שלישיים מידע,
                        העשוי לכלול, בין השאר, את כתובת ה-IP שלך וכן שמות משתמש וסיסמאות שלך;
                        בנוסף, שירותים מסוימים (כגון צ'ט עם נציגי שירות בשירותים) מסופקים באמצעות
                        צדדים שלישיים, שגם להם גישה לנתוניך. אנחנו ננקוט באמצעים סבירים להגן על
                        פרטיותך גם במקרים אלה, אבל אל תשתמש בשירותים כאלה אם אינך מסכים להעברת
                        המידע לאחרים.
                    </p>
                    <p dir="RTL">
                        5. לחשוף כל מידע אישי ללא קבלת רשות ממך אם החברה מאמינה בתום לב כי פעולה זו
                        חיונית כדי: (1) לציית לדרישות הדין או למלא הליך משפטי; (2) להגן על הזכויות
                        הקניין שלה או של מי מטעמה; (3) לאכוף הסכם זה; (4) לפעול כדי להגן על
                        האינטרסים של מנוייה או של אחרים, לרבות ספקי התכנים המוצגים בשירותים; (5)
                        במקרי חירום מתאימים, להגנתך, נמסור פרטים עליך.
                    </p>
                    <p dir="RTL">
                        6. להעבירו לספקים, קבלני משנה או צדדים שלישיים המשתפים עמנו פעולה כחלק
                        מהשירותים (לרבות קבלני משנה בתחומים כדוגמת מוקדי שירות, מערכות מידע, ספקי
                        תקשורת, ספקי שירותי מחשוב ענן, בתי דפוס ונותני שירותי הפצת דואר ודיוורים,
                        סוקרים ויועצי שיווק, מנהלי סיכונים, וספקי מיקור חוץ של פעולות עסקיות) ו/או
                        מסייעים בביצוע הוראות הסכם זה או שירותים הנקובים בו.
                    </p>
                    <p dir="RTL">
                        7. להעביר את המידע למעסיק.
                    </p>
                    <p dir="RTL">
                        4. אנו יכולים לנקוט באמצעי אבטחה שונים כדי להגן על השירותים וכן כדי לוודא
                        עמידה בהוראות הסכם זה. האמצעים יכולים לכלול אמצעי ביקורת, סינון ובדיקה ואתה
                        מסכים שננקוט באמצעים אלו.
                    </p>
                    <p dir="RTL">
                        5. לאור הפעילות בסביבה מקוונת, אין באפשרות החברה להבטיח חסינות מוחלטת מפני
                        חדירות למחשביו או חשיפת המידע האגור בידיו. אם למרות אמצעי האבטחה שנוקטת
                        החברה יעלה בידי צד שלישי לחדור למידע אשר מאובטח ושמור בידיו ו/ או להשתמש בו
                        לרעה, לא תהיה לך כל טענה, תביעה או דרישה כלפי החברה ו/או מי מטעמה בהקשר זה.
                    </p>
                    <p dir="RTL">
                        6. אתה, המשתמש, מתחייב לשמור על הכללים הבאים, ביחס לסיסמה אשר באמצעותה תיגש
                        לחשבון המשתמש ותבצע שימוש בשירותים:
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            אסור לגלות את הסיסמה לאחר או להזינה בהודעת דואר אלקטרוני.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. על הסיסמה להכיל 8-16 תווים.
                    </p>
                    <p dir="RTL">
                        3. המשתמש מתבקש להודיע לחברה באופן מידי על כל חשד לשימוש לא מאושר שנעשה
                        בחשבונו.
                    </p>
                    <p dir="RTL">
                        7. <u>מחיקת מידע</u>. תוכל לבקש מחיקתך ממאגרי המידע של החברה באמצעות משלוח
                        בקשה לכתובת support@payro.io. מחיקת מידע תתאפשר רק אם המידע אינו נדרש לנו
                        לצורך אספקת השירותים, להגנה על אינטרסים לגיטימיים שלנו, לציות לדרישה על-פי
                        דין לשמור מידע, לצורך התגוננות מפני תביעות או לצורך קיום הוראות הסכם זה.
                    </p>
                    <p dir="RTL">
                        <strong>8. </strong>
                        <strong><u>אחריות בגין שימושך בשירותים</u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            אתה מתחייב לשפות ולפצות את החברה בגין כל נזק שייגרם לחברה ו/או לצד
                            שלישי כלשהו, לרבות נזקים ישירים, עקיפים, נסיבתיים, תוצאתיים, הפסדי
                            רווחים, פגיעה במוניטין ואשר נובעים ממעשה ו/או מחדל הקשורים בדרך כלשהי
                            לשימושך בשירותים (לרבות בגין מסירת מידע משתמש לשירותים כאמור בסעיף ‏9
                            להלן).
                        </li>
                    </ol>
                    <p dir="RTL">
                        <strong>9. </strong>
                        <strong><u>מידע משתמשים</u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            ייתכן ובמסגרת השירותים תתבקש או תבקש לחלוק איתנו מידע (להלן: "        <strong>מידע משתמש</strong>"). מידע משתמש כאמור יידרש לעמוד בתנאים
                            הבאים ואתה מצהיר כי הוא אכן עומד בהם:
                        </li>
                    </ol>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            מידע המשתמש הינו חוקי ואינו מפר כל חוק או תקנה.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. מידע המשתמש הינו חוקי ואינו מפר כל חוק או תקנה.
                    </p>
                    <p dir="RTL">
                        3. כל הזכויות במידע המשתמש הן בבעלותך המלאה, לרבות
                        <a
                            href="http://www.netlaw.co.il/hotsubject_hotid_5_desc_%D7%96%D7%9B%D7%95%D7%99%D7%95%D7%AA%20%D7%99%D7%95%D7%A6%D7%A8%D7%99%D7%9D%20%D7%96%D7%9B%D7%95%D7%AA%20%D7%9E%D7%95%D7%A1%D7%A8%D7%99%D7%AA%20%D7%90%D7%99%D7%A0%D7%98%D7%A8%D7%A0%D7%98.htm"
                        >
                            זכויות יוצרים
                        </a>
                        ו
                        <a
                            href="http://www.netlaw.co.il/hotsubject_hotid_13_desc_%D7%A1%D7%99%D7%9E%D7%A0%D7%99%20%D7%A1%D7%99%D7%9E%D7%9F%20%D7%9E%D7%A1%D7%97%D7%A8%20%D7%9C%D7%95%D7%92%D7%95%20%D7%91%D7%90%D7%99%D7%A0%D7%98%D7%A8%D7%A0%D7%98.htm"
                        >
                            סימני מסחר
                        </a>
                        ואין במידע המשתמש כדי להפר זכויות קנייניות בכלל, ו
                        <a
                            href="http://www.netlaw.co.il/hotsubject_hotid_17_desc_%D7%A7%D7%A0%D7%99%D7%99%D7%9F%20%D7%A8%D7%95%D7%97%D7%A0%D7%99%20%D7%91%D7%90%D7%99%D7%A0%D7%98%D7%A8%D7%A0%D7%98.htm"
                        >
                            קניין רוחני
                        </a>
                        בפרט, של אחר.
                    </p>
                    <p dir="RTL">
                        4. מידע המשתמש אינו שקרי או מטעה ואינו מהווה
                        <a
                            href="http://www.netlaw.co.il/hotsubject_hotid_6_desc_%D7%9C%D7%A9%D7%95%D7%9F%20%D7%94%D7%A8%D7%A2%20%D7%94%D7%95%D7%A6%D7%90%D7%AA%20%D7%93%D7%99%D7%91%D7%94%20%D7%90%D7%99%D7%A0%D7%98%D7%A8%D7%A0%D7%98%20%D7%A4%D7%95%D7%A8%D7%95%D7%9D%20%D7%A6%D7%99%D7%90%D7%98.htm"
                        >
                            לשון הרע
                        </a>
                        או פוגע בפרטיות ואינו עשוי לגרום לכל פגיעה אחרת האסורה על-פי דין.
                    </p>
                    <p dir="RTL">
                        5. מידע המשתמש אינו כולל כל תוכנת מחשב, קוד מחשב או יישום הכוללים נגיף-מחשב
                        ("וירוס"), לרבות תוכנות-עוינות הידועות כסוס-טרויאני, תולעים (Worms),
                        ואנדלים (Vandals), יישומים מזיקים (Malicious Applications) וכיו"ב.
                    </p>
                    <p dir="RTL">
                        6. אתה מודע לכך שהחברה אינה חייבת להציג את מידע המשתמש בכלל, באופן מלא או
                        בחלקו וכן אין החברה חייבת להציג את מידע המשתמש בנוסח שהתקבל בשירותים.
                    </p>
                    <p dir="RTL">
                        7. אתה מודע לכך שאולי יהיה צורך לערוך, לשנות, להחליף את מידע המשתמש, או כל
                        חלק ממנו, ולכן אתה מאשר לחברה לבצע שינויים במידע ואף להחליפו כליל ללא כל
                        אישור נוסף.
                    </p>
                    <p dir="RTL">
                        8. לא תהיה זכאי לתשלום או תמורה כלשהם בגין העברת, משלוח או העלאת מידע
                        המשתמש לשירותים ו/או הצגתו בשירותים.
                    </p>
                    <p dir="RTL">
                        9. משלוח מידע המשתמש לשירותים מהווה הסכמה והרשאה לחברה לעשות כל שימוש במידע
                        המשתמש לרבות מתן הרשאה בלתי חוזרת לחברה להעביר את המידע לצדדים שלישיים וזאת
                        מבלי שתהיה לחברה חובה כלשהי, למעט על-פי דין, לציין את שמך ו/או את מקור
                        המידע.
                    </p>
                    <p dir="RTL">
                        10. אתה מאשר לחברה להעביר את פרטיך אשר צורפו למידע לצדדים שלישיים במידה
                        ותתקבל פניה ו/או דרישה מצד שלישי לקבל את פרטי שולח מידע המשתמש בגין תוכנו
                        של המידע.
                    </p>
                    <p dir="RTL">
                        2. החברה תהיה רשאית (אך לא חייבת) לפקח על מידע המשתמשים. החברה אינה נושאת
                        בכל אחריות למידע המשתמשים. האחריות הבלעדית למידע משתמש ולכל תוצאה הנובעת
                        ממידע משתמשים ו/או ההסתמכות עליהם, חלה על המשתמש שמסרם לפרסום ו/או על
                        המשתמש שבחר להסתמך על מידע משתמש זה. תכנים אלה אינם מבטאים את דעת החברה,
                        עמדתה ו/או כל המלצה מטעמה ואין בפרסומם כל ערובה לתקפותם, אמינותם, דיוקם או
                        חוקיותם.
                    </p>
                    <p dir="RTL">
                        <strong>10. </strong>
                        <strong><u>קניין רוחני</u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            השירותים על כל מרכיביהם, האפליקציות, הממשקים, הרכיבים, היישומים, העיצוב
                            והמידע הכלולים בהם (להלן: "<strong>הרכיבים</strong>") הינם ויהיו בכל עת
                            קניינו הבלעדי של החברה, למעט אותם רכיבים בשירותים שהזכויות, הבעלות
                            והקניין בהם שייכים לצד שלישי. העתקה כלשהי, הפצה, שידור, פרסום, קישור,
                            או שינוי אחר בשירותים, ללא רשות מפורשת בכתב של החברה, אסורים.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. במידה ותבחר להעלות לשירותים תוכן משתמש (כהגדרתו מעלה) אתה מעניק לחברה
                        רישיון שימוש בלתי מוגבל בזמן, בלתי חוזר, בר העברה ו/או מתן רישיון משנה, ללא
                        תמורה ובכל מקום (או טריטוריה) לעשות כל שימוש בתוכן המשתמש לרבות פירסומו,
                        הפצתו, העתקתו, עריכתו ושיתופו עם צדדים שלישיים.
                    </p>
                    <p dir="RTL">
                        <strong>11. </strong>
                        <strong><u>תכנים מסחריים</u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            החברה לא נושאת בכל אחריות לתכנים מסחריים שיפורסמו בשירותים (לרבות
                            באמצעות קישורים ו/או קישורים עמוקים) (להלן: "        <strong>תכנים מסחריים</strong>") והאחריות הבלעדית לתכנים מסחריים מוטלת
                            על המפרסמים. החברה אינה בודקת ו/או מוודאת ו/או עורכת את תוכן הפרסומים
                            הללו או את אמיתותם. כאמור, האחריות היחידה לתכנים המסחריים ולכל תוצאה
                            שתיגרם משימוש בהם או מהסתמכות עליהם חלה על המפרסמים.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. פרסום תכנים מסחריים בשירותים אינו מהווה המלצה או עידוד לרכוש את
                        השירותים, הנכסים או המוצרים המוצעים בהם למכירה.
                    </p>
                    <p dir="RTL">
                        3. כל עסקה שתיעשה בעקבות תכנים מסחריים המתפרסמים בשירותים, תסוכם ישירות
                        בינך ובין המפרסם הנוגע בדבר. החברה איננה צד לכל עסקה כזו, והיא לא תישא
                        באחריות לשירותים וטובין שיוצעו בתכנים המסחריים בשירותים או ירכשו באמצעותן.
                    </p>
                    <p dir="RTL">
                        4. סימני המסחר ומודעות הפרסומת של מפרסמים בשירותים הינם קניינם של מפרסמים
                        אלה בלבד ואין לעשות בהם שימוש בלא הסכמת המפרסם בכתב ומראש.
                    </p>
                    <p dir="RTL">
                        5. <u>עדכונים בדואר אלקטרוני</u>. אתה מאשר לחברה לשלוח לתיבת הדואר
                        האלקטרוני שלך (בהתאם לפרטים שמסרת לחברה), עדכונים ואישורים ביחס לפעולותיך
                        בשירותים, וכל הודעה אחרת, לרבות מהדורות דואר אלקטרוני של תכני השירותים
                        (newsletter) או פרסומות.
                    </p>
                    <p dir="RTL">
                        <strong>12. </strong>
                        <strong><u>זכות קיזוז</u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            החברה תהיה רשאית וזכאית לקזז כל סכום לו היא זכאית מכל תשלום עתידי לו
                            אתה זכאי. באם נחליט לממש זכות זו נעדכן אותך לגבי כל קיזוז שיבוצע כאמור
                            בכתב. שימוש בזכות קיזוז כאמור לא יגרע מכל זכות חוזית או חוקית אחרת
                            העומדת לחברה.
                        </li>
                    </ol>
                    <p dir="RTL">
                        <strong>13. </strong>
                        <strong><u>דין ומקום שיפוט</u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            מוסכם כי הדין החל על יחסים בין הצדדים הוא הדין הישראלי ודין זה בלבד וכי
                            <a
                                href="http://www.netlaw.co.il/hotsubject_hotid_24_desc_%D7%A1%D7%9E%D7%9B%D7%95%D7%AA%20%D7%A9%D7%99%D7%A4%D7%95%D7%98%20%D7%A9%D7%99%D7%A4%D7%95%D7%98%D7%99%D7%AA%20%D7%9E%D7%A7%D7%95%D7%9E%D7%99%D7%AA%20%D7%A2%D7%A0%D7%99%D7%99%D7%A0%D7%99%D7%AA.htm"
                            >
                                בית המשפט הבלעדי המוסמך
                            </a>
                            לדון בתביעות בין הצדדים הוא בהתאם למהות העניין בבית המשפט המוסמך הממוקם
                            בעיר תל אביב ובבית משפט זה בלבד.
                        </li>
                    </ol>
                    <p dir="RTL">
                        <strong>14. </strong>
                        <strong><u>הודעות ותקשורת איתך </u></strong>
                        <strong><u></u></strong>
                    </p>
                    <ol start="1" type="1">
                        <li dir="RTL">
                            אתה מסכים לכך שהתקשורת בנינו והעברת הודעות בנינו תתבצע באמצעות
                            השירותים, שירותי הודעות אלקטרוניים (כדוגמת WhatsApp או Meta Messenger)
                            ו/או דואר אלקטרוני. לצורך זה נעשה שימוש בפרטים שתמסור לנו במסגרת הרישום
                            לשירותים. באחריותך להודיע לנו במקרה של כל שינוי בפרטי הקשר ללא דיחוי.
                        </li>
                    </ol>
                    <p dir="RTL">
                        2. אתה מסכים לכך שאישור תנאים אלו במסגרת השירותים מהווה חתימה מחייבת וקבלת
                        כלל תנאי הסכם זה על ידך.
                    </p>
                    <p dir="RTL">
                        <strong>
                            זהו סיימנו את החלק המשפטי, עכשיו אתם יכולים להתחיל להפיק ערך מהשירותים
                            שלנו – תהנו!
                        </strong>
                    </p>
                </div>
                {
                  error && !approved && <Text
                        style={{
                            fontSize: "16px",
                            color: "red",
                            textAlign: "right",
                            fontWeight: 400,
                        }}
                    >
                        {error}
                    </Text>
                }

                <Checkbox onChange={onChange}>
                    <div
                        style={{
                            fontFamily: "Noto Sans Hebrew, sans-serif",
                            color: "#2E3136", // Use 'color' instead of 'textColor'
                        }}
                    >
                        {t('terms_conditions_approve_message')}
                    </div>
                </Checkbox>
                <Button variant="newBrand" onClick={handleContinue}>
                    {t("tc_approve_button_text")}
                </Button>
            </Stack>
    )
}

export default TermsConditions
