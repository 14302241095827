import ReactSession from "./ReactSession";

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */

export const Mobile = {
    Android: 1,
    iOS: 2,
    Other: 3
};

export function getMobileOperatingSystem() {

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        //ReactSession.set("mobile_os", Mobile.Android);
        return Mobile.Android;
    }

    if ((/iPad|iPhone|iPod/.test(userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
        //ReactSession.set("mobile_os", Mobile.iOS);
        return Mobile.iOS;
    }

    return Mobile.Other;
}
